import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Box, Container } from "@mui/material";
import { Link } from "react-router-dom";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
  Directions,
} from "react-floating-button-menu";
import { ReactComponent as ChevronRight } from "../../Assets/images/chat.svg";
import { ReactComponent as Closeico } from "../../Assets/images/close.svg";

import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import CallIcon from "@mui/icons-material/Call";
import Tooltip from "@mui/material/Tooltip";
import SubFooter from "./SubFooter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./Footer.css";
import FreshChat from "react-freshchat";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Rcta from "../cta/request_a_call_back";
import AddIcon from "@mui/icons-material/Add";
import { groupProgramsByCategory } from "../../helpers/common.helpers";
import programs from "../../Assets/constants/programs.json";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [categorizedPrograms, setCategorizedPrograms] = useState([]);
  const [cta, setCta] = useState(false);
  const handleClickOpenRcb = () => {
    setCta(true);
  };
  const RcbhandleClose = () => {
    setCta(false);
  };

  const handleFreshchatButtonClick = () => {
    window.fcWidget.open();
  };

  useEffect(() => {
    const fetchPrograms = groupProgramsByCategory(programs);
    setCategorizedPrograms(fetchPrograms || []);
  }, []);

  return (
    <>
      <Box
        className="footer"
        sx={{ display: { xs: "none", lg: "block" }, fontFamily: "circular" }}
      >
        <Container fixed sx={{ maxWidth: "85rem" }}>
          <Grid container item lg={12} sx={{ marginBottom: "2rem" }}>
            <Grid
              item
              lg={8}
              xs={8}
              md={8}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <img
                style={{ width: "9rem" }}
                src="	https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/logo/accredainnew.webp"
                alt=""
              />
              {/* <span className="img-head">
              International School of AI & Data Science
            </span> */}
            </Grid>
            <Grid
              item
              lg={4}
              xs={4}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "#1A73E8",
                  color: "#fff",
                  borderRadius: "6px",
                  textTransform: "none",
                  width: "250px",
                  marginBottom: "6px",
                  "&:hover": {
                    backgroundColor: "#1A73E8",
                    color: "#fff",
                    borderRadius: "6px",
                  },
                }}
                onClick={handleClickOpenRcb}
              >
                Schedule 1-on-1 Call Now
              </Button>
              <span className="footer-head-right-text">
                Speak with our Learning Advisor
              </span>
            </Grid>
          </Grid>
          <hr />
          <Grid container lg={12} className="footer-body">
            <Grid item lg={4.5} md={5} sx={{ mt: 4 }}>
              <Typography
                sx={{
                  color: "#fff",
                  textDecoration: "none",
                  marginBottom: "0.2rem!important",
                  fontSize: "20px",
                  ml: 2,
                }}
              >
                Programs
              </Typography>
              {Object.keys(categorizedPrograms).map((category, index) => (
                <Accordion
                  key={index}
                  sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    width: "380px",
                    "&&:before": {
                      borderBottom: "none",
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<AddIcon sx={{ color: "#fff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        textDecoration: "none",
                        marginBottom: "0.2rem!important",
                        fontSize: "17px",
                        fontWeight: "bold",
                        textTransform: category.toLowerCase() === "cxo" ? "uppercase" : "capitalize",
                      }}
                    >
                      {category}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0, ml: 1 }}>
                    <ul className="footer-body-content">
                      {categorizedPrograms &&
                        categorizedPrograms[category].map(
                          (program, innerIndex) => (
                            <li key={`${program.id}-${innerIndex}`}>
                              <Link
                                to={`/programs/${program.id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",
                                    textDecoration: "none",
                                    marginBottom: "0rem!important",
                                    fontSize: "14px",
                                  }}
                                >
                                  {program.university_name} -{" "}
                                  {program.program_name}
                                </Typography>
                              </Link>
                            </li>
                          )
                        )}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              ))}
              {/* ------------------------------------------- */}
            </Grid>
            <Grid item lg={5.5} md={5} sx={{ mt: 4 }}>
              <div className="footer-body-head">
                <Typography
                  sx={{
                    color: "#fff",
                    textDecoration: "none",
                    marginBottom: "0.2rem!important",
                    fontSize: "20px",
                  }}
                >
                  Contact Us
                </Typography>

                <ul className="footer-body-content">
                  <li className="pt-4">
                    <Typography
                      sx={{
                        color: "#fff",
                        textDecoration: "none",
                        marginBottom: "0.2rem!important",
                        fontSize: "14px",
                      }}
                    >
                      <a
                        href="mailto:admissions@accredian.com"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {" "}
                        Email us (For Data Science Queries):
                        admissions@accredian.com
                      </a>
                    </Typography>
                  </li>

                  <li className="pt-4">
                    <Typography
                      sx={{
                        color: "#fff",
                        textDecoration: "none",
                        marginBottom: "0.2rem!important",
                        fontSize: "14px",
                      }}
                    >
                      <a
                        href="mailto:pm@accredian.com"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        Email us (For Product Management
                        Queries):pm@accredian.com
                      </a>
                    </Typography>
                  </li>
                  <li className="pt-4">
                    <Typography
                      sx={{
                        color: "#fff",
                        textDecoration: "none",
                        marginBottom: "0.2rem!important",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      <a
                        href="tel:+919079653292"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {" "}
                        Data Science Admission Helpline:+91 9079653292 (9 AM - 7
                        PM)
                      </a>
                    </Typography>
                  </li>
                  <li className="pt-4">
                    <Typography
                      sx={{
                        color: "#fff",
                        textDecoration: "none",
                        marginBottom: "0.2rem!important",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      <a
                        href="tel:+919625811095"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        {" "}
                        Product Management Admission Helpline:+91 9625811095
                      </a>
                    </Typography>
                  </li>
                  <li className="pt-4">
                    <Typography
                      sx={{
                        color: "#fff",
                        textDecoration: "none",
                        marginBottom: "0.2rem!important",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      <h1>
                        Enrolled Student Helpline:{" "}
                        <a
                          href="tel:07969547220"
                          style={{ textDecoration: "none", color: "#fff" }}
                        >
                          07969547220
                        </a>
                      </h1>
                    </Typography>
                  </li>
                  <li className="pt-4">
                    <Typography
                      sx={{
                        color: "#fff",
                        textDecoration: "none",
                        marginBottom: "0.2rem!important",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      Office Address: 4th Floor, 250, Phase IV, Udyog Vihar,
                      Sector 18, Gurugram, Haryana 122015
                    </Typography>
                  </li>
                </ul>
                {/* <Typography
                  sx={{
                    color: "#fff",
                    textDecoration: "none",
                    marginBottom: "0rem!important",
                    fontSize: "20px",
                    fontWeight: 300,
                  }}
                >
                  Why Accredian
                </Typography> */}
                <ul className="footer-body-content">
                  <li className="pt-4 pb-4">
                    <Link to="/whyaccredian" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          marginBottom: "0rem!important",
                          fontSize: "18px",
                        }}
                      >
                        Why Accredian
                      </Typography>
                    </Link>
                  </li>
                </ul>
                <Typography
                  sx={{
                    color: "#fff",
                    textDecoration: "none",
                    marginBottom: "0.3rem!important",
                    fontSize: "20px",
                    fontWeight: 300,
                  }}
                >
                  Follow Us
                </Typography>
                <a
                  href="https://www.facebook.com/insaidlearn"
                  rel="noreferrer"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <FacebookIcon sx={{ fontSize: "1.7rem" }} />
                </a>
                <a
                  href="https://www.linkedin.com/school/insaid/"
                  rel="noreferrer"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <LinkedInIcon sx={{ ml: 2, fontSize: "1.7rem" }} />
                </a>
                <a
                  href="https://twitter.com/insaidlearn"
                  rel="noreferrer"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {" "}
                  <TwitterIcon sx={{ ml: 2, fontSize: "1.7rem" }} />
                </a>
                <a
                  href="https://www.instagram.com/insaid.learn/"
                  rel="noreferrer"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <InstagramIcon sx={{ ml: 2, fontSize: "1.7rem" }} />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCE0L_4ADPU2iyKnDJ0xRzyA"
                  rel="noreferrer"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <YouTubeIcon sx={{ ml: 2, fontSize: "1.7rem" }} />
                </a>
              </div>
            </Grid>
            <Grid item lg={2} sx={{ mt: 4 }}>
              <div className="footer-body-head">
                <Typography
                  sx={{
                    color: "#fff",
                    textDecoration: "none",
                    marginBottom: "0.2rem!important",
                    fontSize: "20px",
                  }}
                >
                  Accredian
                </Typography>
                <ul className="footer-body-content">
                  <li className="pt-4">
                    <Link to="/About" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          marginBottom: "0.2rem!important",
                          fontSize: "14px",
                        }}
                      >
                        About
                      </Typography>
                    </Link>
                  </li>
                  <li className="pt-4">
                    <Link to="/Career" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          marginBottom: "0.2rem!important",
                          fontSize: "14px",
                        }}
                      >
                        Career
                      </Typography>
                    </Link>
                  </li>
                  <li className="pt-4">
                    <a
                      href="https://blog.accredian.com/"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          marginBottom: "0.2rem!important",
                          fontSize: "14px",
                        }}
                      >
                        Blog
                      </Typography>
                    </a>
                  </li>

                  <li className="pt-4">
                    <Link
                      to={"/policies/accredian-policy"}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          marginBottom: "0.2rem!important",
                          fontSize: "14px",
                        }}
                      >
                        Admission Policy
                      </Typography>
                    </Link>
                  </li>
                  <li className="pt-4">
                    <Link
                      to={"/terms/referral"}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          marginBottom: "0.2rem!important",
                          fontSize: "14px",
                        }}
                      >
                        Referral Policy
                      </Typography>
                    </Link>
                  </li>
                  <li className="pt-4">
                    <Link
                      to={"/terms/privacy"}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          marginBottom: "0.2rem!important",
                          fontSize: "14px",
                        }}
                      >
                        Privacy Policy
                      </Typography>
                    </Link>
                  </li>
                  <li className="pt-4">
                    <Link
                      to={"/terms/refund"}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          marginBottom: "0.2rem!important",
                          fontSize: "14px",
                        }}
                      >
                        Refund Policy
                      </Typography>
                    </Link>
                  </li>
                  <li className="pt-4">
                    <Link
                      to={"/terms/cancellation"}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          marginBottom: "0.2rem!important",
                          fontSize: "14px",
                        }}
                      >
                        Cancellation Policy
                      </Typography>
                    </Link>
                  </li>
                  <li className="pt-4">
                    <Link to={"/terms"} style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          marginBottom: "0.2rem!important",
                          fontSize: "14px",
                        }}
                      >
                        Terms Of Service
                      </Typography>
                    </Link>
                  </li>
                  <li className="pt-4">
                    <Link to="/Faq" style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          marginBottom: "0.2rem!important",
                          fontSize: "14px",
                        }}
                      >
                        Master FAQs
                      </Typography>
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/Gallery" style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        textDecoration: "none",
                        marginBottom: "0.2rem!important",
                        fontSize: "14px",
                      }}
                    >
                      Gallery
                    </Typography>
                  </Link>
                </li> */}
                </ul>
              </div>
            </Grid>
          </Grid>
          <Box>
            <Typography
              sx={{
                color: "#fff",
                textAlign: "center",
                py: 5,
                marginBottom: "0rem!important",
                fontSize: "14px",
              }}
            >
              © 2024 Accredian A Brand of FullStack Education Pvt Ltd. All
              Rights Reserved
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          display: { xs: "block", lg: "none", md: "block", sm: "block" },
          backgroundColor: "#000",
        }}
      >
        {/* <Grid container item lg={12} >
          <Grid item xs={12} sx={{display:"flex", flexDirection:"column"}}>
           <center> 
                <CardMedia
            component="img"
            image="https://www.insaid.co/wp-content/uploads/2021/01/white-logo.png"
            alt="green iguana"
            sx={{ pt:4,width:"180px" }}
          />
            
            </center>
          </Grid>
          <Grid item xs={12} sx={{ py:3}}>
          <center>  <Button variant="contained">
              Schedule 1-on-1 Call Now
            </Button></center>
            
          </Grid>
        </Grid> */}
        <Typography
          sx={{
            color: "#fff",
            textAlign: "center",
            py: 5,
            marginBottom: "0rem!important",
            fontSize: "16px",
          }}
        >
          © 2024 Accredian A Brand of FullStack Education Pvt Ltd. All Rights
          Reserved
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* <Box sx={{ml:3}}> */}
        {/* <FloatingMenu
          style={{ marginRight: "60px" }}
          slideSpeed={100}
          isOpen={isOpen}
          spacing={50}
          // direction={Directions.Right}
          className="menu-btn"
          background="linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
        >
          <a
            class="mr-2"
            href="https://api.whatsapp.com/send?phone=918287249844&&text=Hi!%20I’d%20like%20to%20chat%20with%20an%20expert"
            target="_blank"
          >
            <img
              src="https://accredian.com/wp-content/uploads/2023/08/whatsapp.png"
              style={{ height: "53px" }}
              alt="whats app logo"
            />
          </a>
        </FloatingMenu> */}

        {/* </Box> */}

        <FloatingMenu
          slideSpeed={500}
          isOpen={isOpen}
          spacing={20}
          direction={Directions.Up}
          className="menu-btn"
          background="linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
        >
          <MainButton
            //  style={{paddingBottom:"270px"}}
            isOpen={isOpen}
            iconResting={<ChevronRight />}
            iconActive={<Closeico />}
            background="transparent"
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
            size={56}
          />

          <ChildButton
            icon={
              <Tooltip title="Live Chat" placement="left-start">
                <ChatBubbleOutlineIcon
                  onClick={handleFreshchatButtonClick}
                  style={{ fontSize: 20, color: "#fff" }}
                />
              </Tooltip>
            }
            background="#007bff"
            size={40}
            style={{ marginbottom: "6px" }}
          ></ChildButton>

          <ChildButton
            icon={
              <Tooltip title="Request Call Back" placement="left-start">
                <CallIcon
                  onClick={handleClickOpenRcb}
                  style={{ fontSize: 20, color: "#fff" }}
                />
              </Tooltip>
            }
            background="#007bff"
            size={40}
          />
        </FloatingMenu>
      </Box>

      <SubFooter />
      <Rcta open={cta} handleClose={RcbhandleClose} />
      {/* {isFreshchatOpen && ( */}
      <FreshChat
        token={process.env.REACT_APP_FRESHCHAT_TOKEN}
        host={"https://insaid.freshchat.com"}
        email="user@email.com"
        // onWidgetClose={()=>{setisFreshchatOpen(false)}}
        config={{
          headerProperty: {
            hideChatButton: true,
            backgroundColor: "#3498db",
            foregroundColor: "#ffffff",
          },
        }}
        externalId={"john.doe1987"} // user’s id unique to your system
        first_name="..."
        onInit={(widget) => {
          // //console.log(888, widget.open());
          // //console.log(111, widget.user.get());
          // //console.log(
          //   333,
          //   widget.on("user:created", function (resp) {
          //     //console.log(11, resp);
          //     var status = resp && resp.status,
          //       data = resp && resp.data;
          //   })
          // );
        }}
        onHide={(widget) => {
          // //console.log(11, widget);
        }}
      />
      {/* )} */}
    </>
  );
};

export default Footer;
